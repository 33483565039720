:root {
  --background-color: #0d1117;
  --card-background: #0d1117;
  --card-border: #0095f6;
  --text-primary: #EEEEEE;
  --text-secondary: #A0A1A7;
  --accent-color: #0095f6;
  --accent-hover: #0095f6;
  
  --font-size-base: 1rem;
  --font-size-large: 1.2rem;
  --font-size-xl: 1.5rem;
  --font-size-small: 0.9rem;
}

/* Update the cards container to take full width and center content */


.pull-card {
  width: 100%;
  background-color: #111111;
  overflow: hidden;
  border-radius: 20px; /* Ajusta el valor según el nivel de redondeado deseado */
  border: 1px solid var(--border-color)

}

.card-image {
  padding: 20px;
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  position: relative;
}

.aspect-w-1 {
  width: 100%;
  height: 100%;
  position: relative;
}

.aspect-w-1 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.card-content {
  padding: 0 20px 20px;
}

.product-name {
  font-size: var(--font-size-large);
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--text-primary);
}

.price-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.current-price {
  font-size: var(--font-size-xl);
  font-weight: 700;
  color: var(--text-primary);
}

.original-price {
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  text-decoration: line-through;
  margin-left: 10px;
}

.cart-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.add-to-cart, .quantity-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--accent-color);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid var(--accent-color);
}

.add-to-cart {
  padding: 10px 20px;
  border-radius: 8px;
}

.add-to-cart:hover, .add-to-cart.in-cart, .quantity-btn:hover {
  background-color: var(--accent-hover);
  color: var(--text-primary);
}

.add-to-cart svg {
  margin-right: 5px;
}

.quantity-controls {
  display: flex;
  align-items: center;
}

.quantity-btn {
  width: 30px;
  height: 30px;
  font-size: var(--font-size-large);
  border-radius: 4px;
}

.quantity {
  margin: 0 10px;
  font-weight: 600;
  color: var(--text-primary);
}

.progress-container {
  margin-bottom: 15px;
}

.progress-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: var(--text-secondary);
  font-size: var(--font-size-small);
}

.progress-stats span {
  display: flex;
  align-items: center;
}

.progress-stats svg {
  margin-right: 5px;
  color: var(--accent-color);
}

.progress-bar {
  height: 4px;
  background-color: #34383D;
  border-radius: 2px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background-color: var(--accent-color);
  transition: width 0.3s ease;
}

.discount-info {
  text-align: center;
  color: var(--text-secondary);
  font-size: var(--font-size-small);
  margin-top: 15px;
}

@media (max-width: 768px) {
  .pull-card {
    margin: 10px 0;
    width: 100%;
  }

  .cart-controls {
    flex-direction: column;
    align-items: stretch;
  }

  .add-to-cart {
    margin-bottom: 10px;
  }

  .quantity-controls {
    justify-content: center;
  }
}