@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.navbar {
  background-color: #0a0a0a;  /* Matching LandingPage bg color with transparency */
  backdrop-filter: blur(10px);
  position: fixed;
  width: 72px;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 50;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  display: flex;
  flex-direction: column;
  border-right: 1px solid #0a0a0a;
}

.navbar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 0;
}

.navbar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  margin-bottom: 20px;
}

.logo-svg {
  width: 32px;
  height: 32px;
  fill: #0095f6;  /* Accent color from the palette */
}

.navbar-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.navbar-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 0;
  color: #EEEEEE;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
}

.navbar-link:hover {
  background-color: rgba(0, 173, 181, 0.1);  /* Using #00ADB5 with opacity */
}

.navbar-link:hover svg {
  color: #0095f6;  /* Accent color on hover */
}

.navbar-link::after {
  content: attr(title);
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #0095f6;  /* Accent color for tooltips */
  color: #EEEEEE;
  padding: 5px 10px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  white-space: nowrap;
  font-size: 14px;
  margin-left: 10px;
}

.navbar-link:hover::after {
  opacity: 1;
}

.navbar-auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  padding: 20px 0;
}

.navbar-username {
  display: none;
  color: #EEEEEE;
}

.navbar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
  color: #EEEEEE;
}

.navbar-button:hover {
  background-color: rgba(0, 173, 181, 0.1);
}

.navbar-button:hover svg {
  color: #00ADB5;  /* Accent color on hover */
}

@media (max-width: 768px) {
  .navbar {
    width: 100%;
    height: 60px;
    bottom: 0;
    top: auto;
    flex-direction: row;
    border-right: none;
    border-top: 1px solid rgba(223, 224, 212, 0.2);
  }

  .navbar-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    width: 100%;
  }

  .navbar-logo {
    order: 1;
    margin-bottom: 0;
    padding: 0;
  }

  .navbar-links {
    flex-direction: row;
    justify-content: center;
    order: 2;
    flex-grow: 1;
  }

  .navbar-link {
    padding: 0 15px;
  }

  .navbar-link::after {
    content: attr(title);
    position: absolute;
    left: 50%;
    top: -30px;
    transform: translateX(-50%);
    margin-left: 0;
  }

  .navbar-auth {
    flex-direction: row;
    order: 3;
    padding: 0;
    margin-top: 0;
  }

  .navbar-button {
    width: 40px;
    height: 40px;
    margin-top: 0;
    margin-left: 10px;
  }
}