:root {
  --bg-color: #0a0a0a;
  --text-color: #EEEEEE;
  --primary-color: #0095f6;
  --card-bg-color: #111111;
  --border-color: #343535;
  --border-radius: 8px;
  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 20px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 20px;
  --accent-hover: #0095f6;
  --text-secondary: #EEEEEE;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: var(--font-size-base);
  padding-bottom: 60px;
  margin: 0;
  line-height: 1.5;
}

.page-container {
  max-width: 550px;
  margin: 0 auto;
  padding: var(--spacing-lg) var(--spacing-md);
}

.page-title {
  font-size: var(--font-size-xl);
  font-weight: 600;
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.card {
  background-color: #111111;
  padding: var(--spacing-md);
  border-radius: 20px; /* Ajusta el valor según el nivel de redondeado deseado */
  border: 1px solid var(--border-color)
}
.item {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  gap: var(--spacing-sm);
  align-items: center;
  padding: var(--spacing-sm) 0;
  border-bottom: 1px solid var(--border-color);
}

.item:last-child {
  border-bottom: none;
}

.item-name {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item-subtotal {
  text-align: right;
}

.btn,
.add-to-cart,
.quantity-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--accent-color);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid var(--accent-color);
  padding: 5px 5px;
  border-radius: 8px;
}

.btn:hover,
.add-to-cart:hover,
.quantity-btn:hover,
.add-to-cart.in-cart {

  background-color: var(--accent-hover);
  color: var(--text-primary);
  
}

.btn:disabled {
  background-color: #4a4a4a;
  cursor: not-allowed;
}

.add-to-cart,
.quantity-btn {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.add-to-cart svg {
  margin-right: 5px;
}

.quantity-btn {
  width: 30px;
  height: 30px;
  font-size: var(--font-size-lg);
  padding: 0;
}

.total {
  font-size: var(--font-size-lg);
  font-weight: 600;
  text-align: right;
  margin-top: var(--spacing-md);
  padding-top: var(--spacing-md);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-lg);
}

.pagination-btn {
  margin: 0 4px;
}

.confirm-container {
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  background-color: var(--card-bg-color);
  padding: var(--spacing-sm);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 40;
  border-top: 1px solid var(--border-color);
}

.confirm-btn {
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  display: block;
}

.address-card {
  margin-top: var(--spacing-lg);
}

.address-title {
  color: var(--primary-color);
  font-size: var(--font-size-lg);
  font-weight: 600;
  margin-bottom: var(--spacing-sm);
}

@media (max-width: 768px) {
  .item {
    grid-template-columns: 1fr 1fr auto;
  }

  .item-name {
    grid-column: 1 / -1;
    white-space: normal;
  }

  .quantity-controls {
    grid-column: 1 / 2;
    justify-self: start;
  }

  .item-subtotal {
    grid-column: 2 / 3;
    justify-self: end;
  }

  .item-remove {
    grid-column: 3 / -1;
    justify-self: end;
  }
}


/* para imputs */
.auth-input-group {
  margin-bottom: var(--spacing-md);
}

.auth-label {
  display: block;
  margin-bottom: var(--spacing-sm);
  font-weight: 600;
}

.auth-input {
  width: 100%;
  padding: var(--spacing-sm);
  background-color: var(--card-bg-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  color: var(--text-color);
  font-size: var(--font-size-base);
}

.auth-error {
  color: #ff6b6b;
  font-size: 14px;
  margin-top: var(--spacing-sm);
}

.image-preview img {
  max-width: 200px;
  margin-top: var(--spacing-sm);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}


input[type="file"] {
  border: 1px solid var(--border-color);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius);
  background-color: var(--card-bg-color);
  color: var(--text-color);
  width: 100%;
  cursor: pointer;
}

input[type="file"]::file-selector-button {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

input[type="file"]::file-selector-button:hover {
  background-color: var(--accent-hover);
}

